






























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { toasts } from '@/utils/toasts';

import {
  changePassword,
} from './profile-api';

@Component
export default class extends Vue {
  oldPassword = null;
  newPassword = null;
  retypedNewPassword = null;

  async onPasswordChangeModalOk(event) {
    event.preventDefault();
    try {
      if (!this.oldPassword || !this.newPassword || !this.retypedNewPassword) {
        toasts.error('Completati toate campurile');
        return;
      }
      if (this.newPassword !== this.retypedNewPassword) {
        toasts.error('Parolele introduse nu se potrivesc');
        return;
      }

      await changePassword(this.oldPassword, this.newPassword, this.retypedNewPassword);
      toasts.success('Parola a fost schimbata cu succes');
      (<any>(this.$refs.changePasswordModal)).hide();
    } catch (err) {
      toasts.error(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  showModal() {
    (<any>(this.$refs.changePasswordModal)).show();
    this.initializeData();
  }

  initializeData() {
    this.oldPassword = null;
    this.newPassword = null;
    this.retypedNewPassword = null;
  }
}

